<template>
  <div>
    <div class="box-header with-border">
      <router-link :to="{ name: 'task.car_search_checklist.create' }"
                   class="btn btn-success pull-right"
                   title="Добавить"
                   type="button">
        <i class="fa fa-plus"></i>
      </router-link>
    </div>

    <div class="box-body">
      <div class="row" v-if="task_cars_search_checklists.length > 0">
        <div class="table-responsive">
          <table class="table table-borderless table-sm">
            <thead>
            <tr>
              <th scope="col" class="border-top-0">Авто</th>
              <th scope="col" class="border-top-0">Владелец</th>
              <th scope="col" class="border-top-0">Дата проверки</th>
              <th scope="col" class="border-top-0"></th>
              <th scope="col" class="border-top-0">Информация</th>
              <th scope="col" class="border-top-0">Добавил</th>
              <th scope="col" class="border-top-0" style="min-width: 180px;"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="taskCarSearchChecklistItem in task_cars_search_checklists">
              <th scope="row" class="font-scale font-medium">
                <strong>
                  {{ taskCarSearchChecklistItem.title }} {{ taskCarSearchChecklistItem.car_info.year }} г.
                </strong>
                <small>
                  <br />
                  {{ taskCarSearchChecklistItem.car_info.reg_number }}
                  <br />
                  Пробег: <strong>{{ taskCarSearchChecklistItem.car_info.race }}</strong> тыс. км.
                </small>
              </th>
              <td>
                <strong>{{ taskCarSearchChecklistItem.general.owner_name }}</strong>
                <small>
                  <br />
                  <a :href="'tel:' + taskCarSearchChecklistItem.general.owner_phone" target="_blank">
                    {{ taskCarSearchChecklistItem.general.owner_phone }}
                  </a>
                </small>
              </td>
              <td>
                {{ taskCarSearchChecklistItem.general.inspection_date }}
                {{ taskCarSearchChecklistItem.general.inspection_time }}
              </td>
              <td>
                <img v-if="taskCarSearchChecklistItem.photos && taskCarSearchChecklistItem.photos.length > 0"
                     class="img-fluid"
                     style="max-width: 165px;"
                     :src="'/media/' + taskCarSearchChecklistItem.photos[0]"
                     alt="">
              </td>
              <td>
                <small>
                  Состояние авто: <strong>{{ taskCarSearchChecklistItem.overall_condition_score }}</strong>/5
                  <br />
                  Повреждений салона: <strong>{{ taskCarSearchChecklistItem.interior_damages_count }}</strong>
                  <br />
                  Технических повреждений: <strong>{{ taskCarSearchChecklistItem.technical_damages_count }}</strong>
                  <br />
                  Ошибок диагностики: <strong>{{ taskCarSearchChecklistItem.computer_diagnostic_errors_count }}</strong>
                </small>
              </td>
              <td>
                {{ taskCarSearchChecklistItem.creator.title }}
                <small>
                  <br />
                  {{ taskCarSearchChecklistItem.created_date }}
                </small>
              </td>
              <td>
                <a :href="$clientAppUrl +'/cars_search/checklist/' + taskCarSearchChecklistItem.id"
                   class="btn btn-info pull-left"
                   title="Открыть клиентскую версию отчета"
                   style="margin: 3px 2px 2px 3px"
                   target="_blank">
                  <i class="fas fa-external-link-square-alt"></i>
                </a>

                <router-link :to="{
                               name: 'task.car_search_checklist.edit',
                               params: {
                                 taskId: taskCarSearchChecklistItem.task_id,
                                 carSearchChecklistId: taskCarSearchChecklistItem.id
                               }
                             }"
                             class="btn btn-primary pull-left"
                             style="margin: 3px 2px 2px 3px"
                             title="Редактировать">
                  <i class="fa fa-edit"></i>
                </router-link>

                <button class="btn btn-danger pull-left" style="margin: 3px 2px 2px 3px"
                        title="Удалить" type="button"
                        v-on:click="removeCarSearchChecklist(
                          taskCarSearchChecklistItem.task_id,
                          taskCarSearchChecklistItem.id
                        )">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="panel panel-default" v-else>
        <div class="panel-body text-center text-muted mt-5 mb-5">
          Нет элементов для отображения
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "task-cars-search-checklists",

  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    task_cars_search_checklists: [],
  }),

  async created() {
    try {
      const task_cars_search_checklists_response = await API.apiClient.get(
        "/task/" + this.taskId + "/cars_search", {
          params: {
            relations: [
              "creator",
            ],
            filters_list: {
              is_paginated: 0,
              sorting: "desc",
              sort_column: "id",
            },
          },
        });

      this.task_cars_search_checklists = task_cars_search_checklists_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    removeCarSearchChecklist(taskId, taskCarSearchChecklistItemId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/task/' + taskId + '/cars_search/' + taskCarSearchChecklistItemId).then(() => {
          showSuccess();
          setTimeout(() => window.location.reload(), 500);
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
};
</script>

<style scoped>

</style>
